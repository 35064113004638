import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/project/header";
import Intro from "../../components/project/intro";
import TextRow from "../../components/project/text-row";
import Challenge from "../../components/project/challenge";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "../../components/heading/heading";

function TvMaze() {
  const { t } = useTranslation("translations", {
    keyPrefix: "tvMaze",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="project">
      <Header
        projectName={"tvMaze"}
        imgSrc={require("../../assets/images/tvmaze.png")}
      />
      <Container fluid>
        <Intro projectName={"tvMaze"} />
        <div className="challenge">
          <Challenge projectName={"tvMaze"} />
        </div>
        <TextRow projectName="tvMaze" text={"techStack"} title="Tech stack" />
        <Container fluid>
          <Row>
            <Col md="12">
              <Heading
                element="h2"
                variant="project-subtitle"
                text={"Final UI"}
              />
            </Col>
            <Col md="12">
              <figure>
                <img
                  src={require("../../assets/images/tv-maze/tv-maze3.png")}
                />
              </figure>
            </Col>
            <Col md="12">
              <figure>
                <img src={require("../../assets/images/tv-maze/tv-maze.png")} />
              </figure>
            </Col>
          </Row>
        </Container>
        <TextRow projectName="tvMaze" text="outcome" title="Outcome" />
      </Container>
    </div>
  );
}

export default TvMaze;
