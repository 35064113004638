import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Heading from "../components/heading/heading";
import { Container, Row, Col } from "react-bootstrap";
import { experience } from "../data/experience";
import { education } from "../data/education";

interface AboutProps {
  windowDimensions: { width: number; height: number };
}

const About: React.FC<AboutProps> = ({ windowDimensions }) => {
  const { t } = useTranslation("translations", { keyPrefix: "about" });

  // Scroll to top when routing
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <motion.div // Apply animation to the entire about section
        initial={{ opacity: 0 }} // Initial animation values
        animate={{ opacity: 1 }} // Animation when component appears
        transition={{ duration: 0.5 }} // Animation duration
        className="about"
      >
        <div className="about-container">
          <Container fluid>
            <Row>
              <Col md="6">
                <motion.div // Apply animation to the about description
                  initial={{ opacity: 0, y: 50 }} // Initial animation values
                  animate={{ opacity: 1, y: 0 }} // Animation when component appears
                  transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
                  className="about-description"
                >
                  <Heading
                    element="h6"
                    variant="secondary"
                    text={t("preamble")}
                  />
                  <Heading
                    element="h3"
                    variant="about-title"
                    text={t("title")}
                  />
                  <Heading
                    element="p"
                    variant="about-description"
                    text={t("descriptionPart1")}
                  />
                  <Heading
                    element="p"
                    variant="about-description"
                    text={t("descriptionPart2")}
                  />
                  <Heading
                    element="p"
                    variant="about-description"
                    text={t("descriptionPart3")}
                  />
                </motion.div>
              </Col>
              <Col md="6">
                <motion.div // Apply animation to the about image
                  initial={{ opacity: 0, x: 50 }} // Initial animation values
                  animate={{ opacity: 1, x: 0 }} // Animation when component appears
                  transition={{ duration: 0.5, delay: 0.4 }} // Animation duration and delay
                  className="about-figure"
                >
                  <figure className="about-image">
                    <img
                      src={require("../assets/images/josefine.png")}
                      alt="Josefine Löfgren Portrait"
                    />
                  </figure>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </div>
      </motion.div>
      <motion.div // Apply animation to the skills section
        initial={{ opacity: 0 }} // Initial animation values
        animate={{ opacity: 1 }} // Animation when component appears
        transition={{ duration: 0.5, delay: 0.6 }} // Animation duration and delay
        className="skills"
      >
        <Container fluid>
          <Row>
            <Col md="6">
              <Heading
                element="h5"
                variant="about-subtitle"
                text={"Education"}
              />
              {education.map((item, index) => (
                <motion.div // Apply animation to each education item
                  key={index}
                  initial={{ opacity: 0, y: 30 }} // Initial animation values
                  animate={{ opacity: 1, y: 0 }} // Animation when component appears
                  transition={{ duration: 0.5, delay: index * 0.1 }} // Animation duration and delay
                  className="education-item"
                >
                  <Heading element="h6" text={item.title} />
                  <Heading
                    element="p"
                    variant="about-school"
                    text={`${item.school}, ${item.date}`}
                  />
                </motion.div>
              ))}
              <div
                className={windowDimensions.width < 768 ? "border-bottom" : ""}
              />
            </Col>
            <Col md="6">
              <Heading
                element="h5"
                variant="about-subtitle"
                text={"Experience"}
              />
              {experience.map((item, index) => (
                <motion.div // Apply animation to each experience item
                  key={index}
                  initial={{ opacity: 0, y: 30 }} // Initial animation values
                  animate={{ opacity: 1, y: 0 }} // Animation when component appears
                  transition={{ duration: 0.5, delay: index * 0.1 }} // Animation duration and delay
                  className="experience-item"
                >
                  <Heading element="h6" text={item.title} />
                  <Heading
                    element="p"
                    variant="about-company"
                    text={`${item.company}, ${item.date}`}
                  />
                </motion.div>
              ))}
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default About;
