import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion

// Import components
import Header from "../components/header/header";
import Card from "../components/card/card";
import { Container, Row, Col } from "react-bootstrap";

// Import data
import { ProjectData } from "../data/project-data";
import { ProjectItem } from "../types/types";

function Home() {
  const [active, setActive] = useState<string>("view all");
  const [projectsLoaded, setProjectsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProjectsLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="home">
      <Header />
      <div className="projects" style={{ minHeight: "500px" }}>
        <Container fluid>
          <Row>
            <div className="categories">
              <ul>
                <li
                  onClick={() => setActive("view all")}
                  className={active === "view all" ? "is-active" : ""}
                >
                  View all
                </li>
                <li>/</li>
                <li
                  onClick={() => setActive("front end")}
                  className={active === "front end" ? "is-active" : ""}
                >
                  Front End
                </li>
                <li>/</li>
                <li
                  onClick={() => setActive("ux")}
                  className={active === "ux" ? "is-active" : ""}
                >
                  UX / UI{" "}
                </li>
              </ul>
            </div>
            {projectsLoaded && (
              <>
                {ProjectData.map((item: ProjectItem, index: number) => {
                  if (item.category.includes(active) || active === "view all") {
                    return (
                      <Col md="6" key={index}>
                        <motion.div
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 0.5,
                            delay: 0.5 + index * 0.8,
                          }}
                        >
                          <Card
                            title={item.title}
                            preamble={item.preamble}
                            button={item.button}
                            image={item.imgSrc}
                            alt={item.imgAlt}
                            labels={item.labels}
                            path={item.path}
                          />
                        </motion.div>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;
